/*
    Title: Single string of the title that will be displayed for crawlers and the tab in the browser
    Description: Mostly for crawlers to show a description of the page in the search engines
*/

export const seoProperties: { [ page: string ]: { title: string, description: string } } = {
	'/contact': {
		description: 'Get in touch with the Invoiss team; we will help you set up your account and connect your payment gateway. We support you from A to Z.',
		title      : 'Get in touch - Invoiss',
	},
	'/pricing': {
		description: 'Invoiss is a FREE platform, but it also offers a wide range of pricing for advanced features. Check out our pricing plans and choose what`s best for your business.',
		title      : 'Pricing Details - Invoiss',
	},
	'/install': {
		description: 'Install the Invoiss on your phone and desktop computer with three simple steps. Invoiss is compatible with all devices.',
		title      : 'Install Invoiss in 3 steps- Invoiss',
	},
	'/clover': {
		description: 'Clover is the primary payment gateway on the Invoiss platform. If you`re a Clover user, you can connect with the Invoiss platform in under 60 seconds, and your invoices and orders will have an online payment button.',
		title      : 'Invoiss for Clover - Invoiss',
	},
	'/about': {
		description: 'Read about Invoiss; our goal has been to help businesses thrive and reach their goals. We are not stopping now; we are just getting started.',
		title      : 'About us - Invoiss',
	},
	'/privacy': {
		description: 'Protecting your company data is our priority, we do NOT sell or share your data to any third party.',
		title      : 'Privacy Policy - Invoiss',
	},
	'/terms': {
		description: 'Protecting your company data is our priority, we do NOT sell or share your data to any third party.',
		title      : 'Terms of services - Invoiss',
	},
	'/help': {
		description: 'Learn more about common questions and answers, or get in touch with our team via live chat.',
		title      : 'Documentation - Invoiss',
	},
};
