import type { Theme, ThemeOptions } from '@mui/material';
import { alpha, colors, createTheme, responsiveFontSizes } from '@mui/material';
import type { Palette } from '@mui/material/styles/createPalette';
import type { DefaultTheme } from '@mui/private-theming';
import { merge } from 'lodash-es';

export const popOverShadow = 25;
export const paperShadow = 26;
export const alphaButtonBoxShadow = 'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(64 68 82 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(64 68 82 / 8%) 0px 2px 5px 0px';
export const alphaButtonBoxShadowHover = 'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(64 68 82 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(64 68 82 / 8%) 0px 3px 9px 0px, rgb(64 68 82 / 8%) 0px 2px 5px 0px';
export const menuDarkModeBoxShadow = '0px 24px 38px 3px hsla(0,0%,0%,0.14), 0px 9px 46px 8px hsla(0,0%,0%,0.12), 0px 11px 15px -7px hsla(0,0%,0%,0.2)';

// Font Families
const fontFamily = [
	'-apple-system',
	'"system-ui"',
	'"Roboto"',
	'"Helvetica Neue"',
	'BlinkMacSystemFont',
	'"Segoe UI"',
	'Roboto',
	'Arial',
	'sans-serif',
	'"Apple Color Emoji"',
	'"Segoe UI Emoji"',
	'"Segoe UI Symbol"',
].join( ',' );

// Common Theme
const commonTheme = {
	palette: {
		primary: { main: '#635BFF' },
		alpha  : {
			primary  : alpha( colors.blue[ 500 ], 0.1 ),
			secondary: alpha( '#635BFF', 0.1 ),
			warning  : alpha( colors.orange[ 500 ], 0.1 ),
			success  : alpha( colors.green[ 500 ], 0.1 ),
			error    : alpha( colors.pink[ 500 ], 0.1 ),
			info     : alpha( colors.grey[ 500 ], 0.1 ),
			amethyst : alpha( 'rgb(223, 130, 224)', 0.1 ),
			main     : '#000000',
			light    : '#000000',
			dark     : '#000000',
		},
		colors: {
			success: { main: '#37a603' },
		},
	},
	typography: {
		htmlFontSize: 10,
		fontFamily,
		h1          : { fontWeight: 700, fontSize: 30 },
		h2          : { fontWeight: 700, fontSize: 25 },
		h3          : { fontWeight: 700, fontSize: 21, lineHeight: 1.4 },
		h4          : { fontWeight: 700, fontSize: 18 },
		h5          : { fontWeight: 700, fontSize: 16 },
		h6          : { fontWeight: 700, fontSize: 14 },
		body1       : { fontSize: 14 },
	},
} as ThemeOptions;

const others: ( theme: DefaultTheme ) => ThemeOptions = ( theme ) => {
	const isDarkMode = theme.palette.mode === 'dark';
	const PaperElevatedBoxShadow = !isDarkMode
		? theme.shadows[ popOverShadow as any ]
		: menuDarkModeBoxShadow;
	return {
		components: {
			MuiBackdrop: {
				styleOverrides: {
					root: {
						'&[style*="opacity: 0"]': {
							pointerEvents: 'none', // Fix for backdrop not being clickable https://github.com/mui/material-ui/issues/32286
						},
					},
				},
			},
			MuiPopover: {
				styleOverrides: {
					root: {
						'&[style*="opacity: 0"]': {
							pointerEvents: 'none', // Fix for backdrop not being clickable https://github.com/mui/material-ui/issues/32286
						},
					},
				},
			},
			MuiPopper: {
				defaultProps  : {},
				styleOverrides: {
					root: {
						'.MuiPaper-root': {
							transition     : 'opacity .25s cubic-bezier(0,1,.4,1),transform .25s cubic-bezier(.18,1.25,.4,1) !important',
							// transform      : 'scale(1) !important',
							border         : 'none',
							boxShadow      : PaperElevatedBoxShadow,
							backgroundColor: !isDarkMode && theme.palette.background.default,
						},
					},
				},
			},
			MuiUseMediaQuery: {
				defaultProps: { noSsr: true },
			},
			MuiPaper: {
				defaultProps: { variant: 'outlined' },
				variants    : [
					{
						props: { variant: 'elevated' },
						style: {
							boxShadow: PaperElevatedBoxShadow,
							border   : 'none',
						},
					} as any, {
						props: { variant: 'borderless' },
						style: {
							boxShadow: 0,
							border   : 'none',
						},
					} as any, {
						props: { variant: 'blur' },
						style: {
							border   : 'none',
							boxShadow: !isDarkMode
								? theme.shadows[ popOverShadow as any ]
								: menuDarkModeBoxShadow,
							backgroundColor: isDarkMode ? 'rgba(31,34,35,0.81)' : '#fefefe77',
							backdropFilter : 'saturate(180%) blur(20px)',
						},
					} as any,
				],
				styleOverrides: {
					root: {
						backgroundImage: 'unset',
						boxShadow      : theme.shadows[ paperShadow as any ],
					},
					rounded: { borderRadius: 7 },
					// bordered: {
					// 	border         : `1px solid ${theme.palette.divider}`,
					// 	borderRadius   : '7px',
					// 	backgroundColor: 'transparent',
					// },
				},
			},
			MuiFormHelperText: {
				styleOverrides: {
					root: { marginLeft: 6 },
				},
			},
			MuiTableBody: {
				styleOverrides: {
					root: {
						'.MuiTableRow-root': {
							transition: '.2s all',
						},
					},
				},
			},
			MuiTextField: {
				defaultProps  : { size: 'small' /*className: 'labelTop'*/ },
				styleOverrides: {
					root: {
						'&.labelTop': {
							'.MuiOutlinedInput-notchedOutline': {
								'top'   : 0,
								'legend': {
									display: 'none',
								},
								'&.Mui-focused': {},
							},
							'.MuiInputLabel-root': {
								'position'     : 'relative',
								'transform'    : 'none',
								'fontWeight'   : 500,
								'color'        : theme.palette.text.primary,
								'paddingBottom': 5,
								'paddingLeft'  : 3,
								'&.Mui-error'  : {
									color: theme.palette.error.main,
								},
							},
						},
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					notchedOutline: { borderColor: colors.grey[ 800 ] },
					root          : {
						'borderRadius'                    : '7px',
						'boxShadow'                       : theme.shadows[ paperShadow as any ],
						'.MuiOutlinedInput-notchedOutline': {
							borderColor: theme.palette.divider,
							transition : '.3s',
						},
						'&:hover .MuiOutlinedInput-notchedOutline': {
							'&.Mui-error ': {
								borderColor: theme.palette.error.main,
							},
							'borderColor': theme.palette.colors.info.hover,
						},
						'&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
							'borderColor' : theme.palette.primary.main,
							'&.Mui-error ': {
								borderColor: theme.palette.error.main,
							},
						},
						'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
							transition: 'none',
						},
						'&.MuiOutlinedInput-root': {
							backgroundColor: isDarkMode
								? theme.palette.background.default
								: '#ffffff',
						},
						'&.MuiOutlinedInput-input': {
							color: theme.palette.text.primary,
						},
						'&.MuiInputAdornment-root svg': {
							color: theme.palette.text.secondary,
						},
					},
				},
			},
			MuiTooltip: {
				defaultProps  : { placement: 'top', arrow: true },
				styleOverrides: {
					arrow: {
						'opacity': 1,
						':before': {
							border: 'none',
						},
						'fontSize': '15px',
						'color'   : isDarkMode ? colors.grey[ 800 ] : '#ffffff',
					},
					tooltip: {
						border         : 'none',
						opacity        : 1,
						borderRadius   : 5,
						backgroundColor: isDarkMode ? colors.grey[ 800 ] : '#ffffff',
						color          : theme.palette.text.primary,
						fontSize       : '12px',
						boxShadow      : PaperElevatedBoxShadow,
					},
				},
			},
			MuiAvatar: {
				styleOverrides: {
					root: {
						color: '#ffffff',
					},
				},
			},
			MuiButtonGroup: {
				defaultProps  : { size: 'small' },
				styleOverrides: {
					root: {
						'.MuiButtonGroup-grouped': {
							minWidth: 'fit-content',
						},
					},
				},
			},
			MuiFormControlLabel: {
				styleOverrides: {
					root: {
						margin: 0,
					},
				},
			},
			MuiAccordion: {
				defaultProps  : { disableGutters: true },
				styleOverrides: {
					root: { /*borderRadius: '7px !important'*/ },
				},
			},
			MuiList: {
				styleOverrides: {
					root: { padding: 4 },
				},
			},
			MuiListItemText: {
				styleOverrides: {
					root: {
						whiteSpace: 'normal !important' as any,
					},
				},
			},
			MuiListItemButton: {
				// defaultProps  : { disableRipple: process.env.NEXT_PUBLIC_IS_CLOVER_MACHINE ? false : true },
				styleOverrides: {
					root: {
						borderRadius: 5,
					},
				},
			},
			MuiListItemIcon: {
				defaultProps  : {},
				styleOverrides: {
					root: {
						svg: {
							fontSize: '2rem !important',
						},
					},
				},
			},
			MuiMenu: {
				// variants      : [
				// 	{
				// 		props: { variant: 'pouya' },
				// 		style: {
				// 			'.MuiPaper-root': {
				// 				backgroundColor: 'orange',
				// 			},
				// 		},
				// 	},
				// ],
				defaultProps: {
					disableScrollLock: true,
					anchorOrigin     : { vertical: 'bottom', horizontal: 'center' },
					transformOrigin  : { vertical: 'top', horizontal: 'center' },
				},
				styleOverrides: {
					// root: ( { ownerState, theme } ) => ( {
					// 	...ownerState?.open && {},
					// } ),
					root: {
						'marginTop'        : 3,
						'.MuiPopover-paper': {
							// backgroundColor: isDarkMode ? colors.grey[ 900 ] : '#ffffff',
							backgroundColor: isDarkMode ? '#212223' : '#ffffff',
							// boxShadow      : theme.shadows[ popOverShadow as any ],
							boxShadow      : PaperElevatedBoxShadow,
							transition     : 'opacity .25s cubic-bezier(0, 1, .4, 1),transform .25s cubic-bezier(.18, 1.25, .4, 1) !important',
							border         : 'none',
							borderColor    : isDarkMode ? alpha( theme.palette.divider, .05 ) : 0,
						},
					},
				},
			},
			MuiMenuItem: {
				// defaultProps  : { disableRipple: process.env.NEXT_PUBLIC_IS_CLOVER_MACHINE ? false : true },
				styleOverrides: {
					root: {
						'borderRadius'  : 5,
						'marginTop'     : 3,
						'marginBottom'  : 3,
						'transition'    : '.2s',
						'&.Mui-selected': {
							background: theme.palette.primary.main,
							color     : '#ffffff',
						},
						'&.Mui-selected:hover': {
							background: theme.palette.primary.main,
							filter    : 'brightness(.8)',
						},
					},
				},
			},
			MuiAutocomplete: {
				styleOverrides: {
					tag  : {},
					paper: {
						backgroundColor: theme.palette.background.default,
					},
					popper: {
						paddingTop     : 5,
						backgroundColor: 'transparent',
					},
					root: {
						// '.MuiOutlinedInput-notchedOutline': {
						// 	borderRadius: '5px',
						// },
					},
					option: {
						'margin'                 : 5,
						'borderRadius'           : 5,
						'&[aria-selected="true"]': {
							background: `${colors.blue[ 500 ]} !important`,
							color     : '#ffffff',
							fontWeight: '500',
						},
						'&[aria-selected="false"]:focus': {
							background: `${colors.blue[ 500 ]} !important`,
						},
					},
					clearIndicator: {
						'background' : alpha( colors.pink[ 500 ], 0.1 ),
						'color'      : colors.pink[ 500 ],
						'marginRight': 8,
						'&:hover'    : {
							background: alpha( colors.pink[ 500 ], 0.3 ),
						},
					},
					popupIndicator: {
						'color'  : colors.grey[ 800 ],
						'&:hover': {
							background: alpha( colors.grey[ 800 ], 0.5 ),
						},
					},
				},
			},
			MuiSwitch: {
				// styleOverrides: {
				// 	root: {
				// 		// '.MuiSwitch-thumb': {},
				// 	},
				// },
			},
			MuiSelect: {
				defaultProps: {
					size     : 'small',
					MenuProps: { style: { zIndex: 9999 } },
				},
			},
			MuiButton: {
				defaultProps: {
					size   : 'small',
					variant: 'contained',
					color  : isDarkMode ? 'primary' : 'alpha',
					height : '30px',
				},
				styleOverrides: {
					root: {
						'transition'   : '.3s all',
						'textTransform': 'capitalize',
						'whiteSpace'   : 'nowrap',
						'minWidth'     : 'max-content',
						'minHeight'    : 'max-content',
						'borderRadius' : 8,
						'boxShadow'    : isDarkMode
							? 'none'
							: theme.shadows[ paperShadow as any ],
						'&.MuiButton-sizeSmall'                                : { height: '30px' },
						'&.MuiButton-containedAlpha, &.MuiButton-outlinedAlpha': {
							'backgroundColor': isDarkMode ? '#000000' : '#ffffff',
							'border'         : 0,
							'borderRadius'   : 8,
							'boxShadow'      : alphaButtonBoxShadow,
							'color'          : theme.palette.colors.info.dark,
							'fontWeight'     : '500',
							'padding'        : '4px 10px',
							':hover'         : {
								// color       : theme.palette.text.primary, // Temporarily disabled
								boxShadow: isDarkMode ? theme.shadows[ 1 ] : alphaButtonBoxShadowHover,
							},
							'&[aria-disabled]': {
								color      : theme.palette.colors.info.main,
								borderColor: isDarkMode ? theme.palette.colors.info.main : theme.palette.colors.info.light,
							},
							'&[disabled]': {
								color      : theme.palette.colors.info.main,
								borderColor: isDarkMode
									? theme.palette.colors.info.main
									: theme.palette.colors.info.light,
								
							},
							'&.MuiLoadingButton-loading': {
								color: 'transparent !important',
							},
						},
						
						'&.MuiButton-containedPrimary': {
							':hover': {
								boxShadow: `0px 0px 5px 0px ${isDarkMode ? colors.blue[ 500 ] : '#645AFF'}`,
							},
						},
						'&.MuiButton-containedSecondary': {
							':hover': {
								boxShadow: `0px 0px 5px 0px ${isDarkMode ? colors.blue[ 500 ] : '#CE93D8'}`,
							},
						},
						'&.MuiButton-containedAmethyst': {
							color: '#ffffff',
						},
						'&.MuiButton-containedInverted': {
							'color' : isDarkMode ? '#000000' : '#ffffff',
							':hover': {
								opacity: .8,
							},
						},
						
						'&.MuiButton-text': {
							boxShadow: 'none',
						},
						
						'&.MuiButton-containedSuccess': {
							'backgroundColor': '#44D600',
							'&[disabled]'    : {
								backgroundColor: theme.palette.divider,
							},
						},
					},
					alpha: {
						'padding'               : '4px 10px',
						'borderRadius'          : 4,
						'fontWeight'            : 500,
						'fontSize'              : '0.8125rem',
						'&.MuiButton-alphaAlpha': {
							backgroundColor: 'orange',
						},
					},
					
				},
			},
			MuiIconButton: {
				styleOverrides: {
					root: { borderRadius: '5px', padding: '3px' },
				},
			},
			MuiCheckbox: {
				styleOverrides: {
					root: {
						borderRadius: '5px',
						padding     : '5px',
					},
				},
			},
			MuiChip: {
				styleOverrides: {
					alpha: {
						'boxShadow'             : 'none',
						'borderRadius'          : 5,
						'textTransform'         : 'capitalize',
						'fontSize'              : 12,
						'fontWeight'            : 500,
						'paddingTop'            : '0px',
						'paddingBottom'         : '0px',
						'paddingRight'          : '0px',
						'paddingLeft'           : '0px',
						'height'                : '22px',
						'backgroundColor'       : theme.palette.alpha.info,
						// 'border'                  : `0.5px solid ${theme.palette.alpha.info}`,
						'color'                 : theme.palette.colors.info.dark,
						'.MuiChip-label'        : { paddingLeft: '4px', paddingRight: '4px' },
						'.MuiChip-deleteIcon'   : { fontSize: 20, marginLeft: '4px' },
						'&.MuiChip-colorPrimary': {
							backgroundColor: theme.palette.alpha.primary,
							// border         : `0.5px solid ${theme.palette.alpha.primary}`,
							border         : 'none !important',
							color          : colors.blue[ 500 ],
						},
						'&.MuiChip-colorSecondary': {
							backgroundColor: alpha( '#635BFF', 0.1 ),
							// border         : `0.5px solid ${alpha( '#635BFF', 0.1 )}`,
							border         : 'none !important',
							color          : '#635BFF',
						},
						'&.MuiChip-colorError': {
							backgroundColor: theme.palette.alpha.error,
							// border         : `0.5px solid ${theme.palette.alpha.error}`,
							border         : 'none !important',
							color          : theme.palette.error.main,
						},
						'&.MuiChip-colorSuccess': {
							backgroundColor: theme.palette.alpha.success,
							// border         : `0.5px solid ${theme.palette.alpha.success}`,
							border         : 'none !important',
							color          : theme.palette.colors.success.main,
						},
						'&.MuiChip-colorWarning': {
							backgroundColor: theme.palette.alpha.warning,
							// border         : `0.5px solid ${theme.palette.alpha.warning}`,
							border         : 'none !important',
							color          : theme.palette.warning.main,
						},
						'&.MuiChip-colorAmethyst': {
							backgroundColor: alpha( 'rgb(223, 130, 224)', 0.1 ),
							// border         : `0.5px solid ${theme.palette.alpha.info}`,
							border         : 'none !important',
							color          : isDarkMode ? 'rgb(223, 130, 224)' : 'rgb(203,12,203)',
						},
						'&.MuiChip-colorInfo': {
							backgroundColor: theme.palette.alpha.info,
							// border         : `0.5px solid ${theme.palette.alpha.info}`,
							border         : 'none !important',
							color          : theme.palette.colors.info.dark,
						},
					},
				} as any,
			},
			MuiCircularProgress: {
				defaultProps: {
					size     : 25,
					thickness: 2.8,
				},
				styleOverrides: {
					root: {
						color: isDarkMode ? colors.grey[ 700 ] : colors.grey[ 500 ],
					},
				},
			},
			MuiDialogTitle: {
				styleOverrides: {
					root: {
						backgroundColor: theme.palette.background.default,
					},
				},
			},
			MuiDialogContent: {
				styleOverrides: {
					root: {
						border: 'none',
					},
				},
			},
			MuiDialogActions: {
				styleOverrides: {
					root: {
						backgroundColor: theme.palette.background.default,
						borderColor    : theme.palette.divider,
					},
				},
			},
			
		},
	};
};

// Light Theme
const _lightTheme = responsiveFontSizes( createTheme( merge( {}, commonTheme, {
	palette: {
		mode     : 'light',
		primary  : { main: '#635BFF' },
		secondary: { main: colors.blue[ 500 ] },
		amethyst : {
			main        : 'rgb(203,12,203)',
			light       : 'rgb(223, 130, 224)',
			dark        : 'rgb(203,12,203)',
			contrastText: '#616161',
		},
		inverted  : { main: '#000000' },
		background: {
			default: '#ffffff',
			paper  : '#F6F8FA',
			alpha  : alpha( colors.common.white, 0.2 ),
		},
		colors: {
			info: {
				main   : colors.grey[ 500 ],
				hover  : colors.grey[ 500 ],
				lighter: colors.grey[ 200 ],
				light  : colors.grey[ 300 ],
				medium : colors.grey[ 600 ],
				dark   : colors.grey[ 800 ],
				darker : colors.grey[ 900 ],
			},
			tabs: {
				segmentControlColor: '#000000',
				segmentControlBg   : '#F4F5F5',
				segmentControlCard : '#ffffff',
			},
			opposite: '#ffffff',
		},
	},
	shadows: [
		'none',
		'0px 0px 16px rgba(0, 0, 0, .2),0px 0px 3px 1px rgb(0, 0, 0, .15)',
		'0px 0px 1px rgba(0,0,0,0.2),0px 1px 1px rgba(0,0,0,0.14)',
		'0px 0px 1px rgba(0,0,0,0.2),0px 2px 2px rgba(0,0,0,0.14)',
		'0px 0px 3px rgba(0,0,0,0.2),0px 3px 4px rgba(0,0,0,0.14)',
		'0px 0px 4px rgba(0,0,0,0.2),0px 4px 5px rgba(0,0,0,0.14)',
		'0px 0px 5px rgba(0,0,0,0.2),0px 5px 8px rgba(0,0,0,0.14)',
		'0px 0px 5px rgba(0,0,0,0.2),0px 6px 10px rgba(0,0,0,0.14)',
		'0px 0px 5px rgba(0,0,0,0.2),0px 7px 10px rgba(0,0,0,0.14)',
		'0px 0px 5px rgba(0,0,0,0.2),0px 8px 10px rgba(0,0,0,0.14)',
		'0px 0px 6px rgba(0,0,0,0.2),0px 9px 12px rgba(0,0,0,0.14)',
		'0px 0px 6px rgba(0,0,0,0.2),0px 10px 14px rgba(0,0,0,0.14)',
		'0px 0px 7px rgba(0,0,0,0.2),0px 11px 15px rgba(0,0,0,0.14)',
		'0px 0px 8px rgba(0,0,0,0.2),0px 12px 17px rgba(0,0,0,0.14)',
		'0px 0px 8px rgba(0,0,0,0.2),0px 13px 19px rgba(0,0,0,0.14)',
		'0px 0px 9px rgba(0,0,0,0.2),0px 14px 21px rgba(0,0,0,0.14)',
		'0px 0px 9px rgba(0,0,0,0.2),0px 15px 22px rgba(0,0,0,0.14)',
		'0px 0px 10px rgba(0,0,0,0.2),0px 16px 24px rgba(0,0,0,0.14)',
		'0px 0px 11px rgba(0,0,0,0.2),0px 17px 26px rgba(0,0,0,0.14)',
		'0px 0px 11px rgba(0,0,0,0.2),0px 18px 28px rgba(0,0,0,0.14)',
		'0px 0px 12px rgba(0,0,0,0.2),0px 19px 29px rgba(0,0,0,0.14)',
		'0px 0px 13px rgba(0,0,0,0.2),0px 20px 31px rgba(0,0,0,0.14)',
		'0px 0px 13px rgba(0,0,0,0.2),0px 21px 33px rgba(0,0,0,0.14)',
		'0px 0px 14px rgba(0,0,0,0.2),0px 22px 35px rgba(0,0,0,0.14)',
		'0px 0px 14px rgba(0,0,0,0.2),0px 15px 36px rgba(0,0,0,0.14)',
		'0 0 0 1px rgb(136 152 170 / 10%), 0 15px 35px 0 rgb(49 49 93 / 10%), 0 5px 15px 0 rgb(0 0 0 / 8%)',
		'0 1px 3px rgba(0,0,0,0.025),0 1px 2px rgba(0,0,0,0.05)',
	],
} ) ), { factor: 1.2 /*variants: [ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6' ]*/ } );
export const lightTheme = merge( {}, _lightTheme, others( _lightTheme as any ) );

// Dark Theme
const _darkTheme = responsiveFontSizes( createTheme( merge( {}, commonTheme, {
	palette: {
		mode     : 'dark',
		primary  : { main: colors.blue[ 500 ] },
		secondary: { main: '#635BFF' },
		amethyst : {
			main        : 'rgb(223, 130, 224)',
			// main        : 'rgb(119,75,122)',
			light       : 'rgb(235,137,236)',
			dark        : 'rgb(45, 3, 45)',
			contrastText: '#616161',
		},
		inverted  : { main: '#ffffff' },
		background: {
			default: '#131517',
			paper  : '#202325',
			alpha  : alpha( '#0d1014', 0.2 ),
		},
		colors: {
			info: {
				main   : colors.grey[ 500 ],
				hover  : colors.grey[ 700 ],
				lighter: colors.grey[ 900 ],
				light  : colors.grey[ 800 ],
				medium : colors.grey[ 600 ],
				dark   : colors.grey[ 300 ],
				darker : colors.grey[ 200 ],
			},
			tabs: {
				segmentControlColor: '#ffffff',
				segmentControlBg   : '#343434',
				segmentControlCard : '#696969',
			},
			opposite: '#000000',
		},
	},
	shadows: [
		'none',
		'0px 0px 15px rgba(0, 0, 0, .2),0px 0px 3px 1px rgb(0, 0, 0, .15)',
		'0px 0px 1px rgba(0,0,0,0.2),0px 1px 1px rgba(0,0,0,0.14)',
		'0px 0px 1px rgba(0,0,0,0.2),0px 2px 2px rgba(0,0,0,0.14)',
		'0px 0px 3px rgba(0,0,0,0.2),0px 3px 4px rgba(0,0,0,0.14)',
		'0px 0px 4px rgba(0,0,0,0.2),0px 4px 5px rgba(0,0,0,0.14)',
		'0px 0px 5px rgba(0,0,0,0.2),0px 5px 8px rgba(0,0,0,0.14)',
		'0px 0px 5px rgba(0,0,0,0.2),0px 6px 10px rgba(0,0,0,0.14)',
		'0px 0px 5px rgba(0,0,0,0.2),0px 7px 10px rgba(0,0,0,0.14)',
		'0px 0px 5px rgba(0,0,0,0.2),0px 8px 10px rgba(0,0,0,0.14)',
		'0px 0px 6px rgba(0,0,0,0.2),0px 9px 12px rgba(0,0,0,0.14)',
		'0px 0px 6px rgba(0,0,0,0.2),0px 10px 14px rgba(0,0,0,0.14)',
		'0px 0px 7px rgba(0,0,0,0.2),0px 11px 15px rgba(0,0,0,0.14)',
		'0px 0px 8px rgba(0,0,0,0.2),0px 12px 17px rgba(0,0,0,0.14)',
		'0px 0px 8px rgba(0,0,0,0.2),0px 13px 19px rgba(0,0,0,0.14)',
		'0px 0px 9px rgba(0,0,0,0.2),0px 14px 21px rgba(0,0,0,0.14)',
		'0px 0px 9px rgba(0,0,0,0.2),0px 15px 22px rgba(0,0,0,0.14)',
		'0px 0px 10px rgba(0,0,0,0.2),0px 16px 24px rgba(0,0,0,0.14)',
		'0px 0px 11px rgba(0,0,0,0.2),0px 17px 26px rgba(0,0,0,0.14)',
		'0px 0px 11px rgba(0,0,0,0.2),0px 18px 28px rgba(0,0,0,0.14)',
		'0px 0px 12px rgba(0,0,0,0.2),0px 19px 29px rgba(0,0,0,0.14)',
		'0px 0px 13px rgba(0,0,0,0.2),0px 20px 31px rgba(0,0,0,0.14)',
		'0px 0px 13px rgba(0,0,0,0.2),0px 21px 33px rgba(0,0,0,0.14)',
		'0px 0px 14px rgba(0,0,0,0.2),0px 22px 35px rgba(0,0,0,0.14)',
		'0px 0px 14px rgba(0,0,0,0.2),0px 0px 36px rgba(0,0,0,0.14)',
		'0 0 0 1px rgb(136 152 170 / 10%), 0 15px 35px 0 rgb(49 49 93 / 10%), 0 5px 15px 0 rgb(0 0 0 / 8%)',
		'0 1px 3px rgba(0,0,0,0.025),0 1px 2px rgba(0,0,0,0.05)',
	],
} ) ), { factor: 1.2 /*variants: [ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6' ]*/ } );
export const darkTheme = merge( {}, _darkTheme, others( _darkTheme as any ) );

// Private Theming
declare module '@mui/private-theming' {
	// noinspection JSUnusedGlobalSymbols
	interface DefaultTheme extends Theme {
		palette: Palette & { alpha: Record<string, string>, colors: Record<string, any> }
	}
}
declare module '@mui/material/Button' {
	// noinspection JSUnusedGlobalSymbols
	interface ButtonPropsVariantOverrides {
		alpha: true
	}
	
	// noinspection JSUnusedGlobalSymbols
	interface ButtonPropsColorOverrides {
		alpha: true,
		inverted: true,
		amethyst: true
	}
}
declare module '@mui/material/Chip' {
	// noinspection JSUnusedGlobalSymbols
	interface ChipPropsVariantOverrides {
		alpha: true
	}
	
	interface ChipPropsColorOverrides {
		amethyst: true
	}
}
declare module '@mui/material/TextField' {
	// noinspection JSUnusedGlobalSymbols
	interface TextFieldPropsVariantOverrides {
		alpha: true
	}
}
declare module '@mui/material/Typography' {
	// noinspection JSUnusedGlobalSymbols
	interface TypographyPropsVariantOverrides {
		bold: true
	}
}
declare module '@mui/material/Paper' {
	// noinspection JSUnusedGlobalSymbols
	interface PaperPropsVariantOverrides {
		bordered: true,
		elevated: true,
		borderless: true,
		blur: true
	}
}
